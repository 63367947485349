export const keywords = {
    header_page_login: "ล็อกอิน",
    header_page_register: "สร้างบัญชีใหม่",
    title_page_login: "เข้าสู่ระบบบัญชี MochiMochi ของคุณ",
    login_with_google: "ลงชื่อเข้าใช้บัญชี Google ของคุณ",
    login_with_facebook: "ลงชื่อเข้าใช้บัญชี Facebook ของคุณ",
    login_with_apple: "ลงชื่อเข้าใช้บัญชี Apple ของคุณ",
    placeholder_input_username_register: "ชื่อของคุณ",
    placeholder_input_email_register: "ใส่อีเมลที่ถูกต้องของคุณ",
    placeholder_input_password_register: "สร้างรหัสผ่าน (เอาให้จำง่ายๆหน่อยนะ)",
    placeholder_input_email_login: "ป้อนอีเมลของคุณ",
    placeholder_input_password_login: "ป้อนรหัสผ่านของคุณ",
    or: "หรือ",
    button_login: "ล็อกอิน",
    button_register: "สร้างบัญชี",
    text_forgot_pw: "หากลืมรหัสผ่าน",
    text_no_account: "หากไม่มีบัญชี",
    text_create_account: "สร้างบัญชีใหม่",
    title_page_register_1 : "คุณต้องการสร้างบัญชีโดยวิธีอะไร",
    title_page_register_2 : "มาสร้างบัญชี Mochi กันเลย",
    register_with_google : "Tạo tài khoản với G+",
    register_with_facebook : "Tạo tài khoản với Facebook",
    message_popup_lost_password: "แชทกับ Mochi เพื่อได้รับการช่วยเหลือในการเปลี่ยนรหัสผ่าน",
    message_popup_login_success: "ล็อกอินสำเร็จเลยนะ",
    message_popup_login_error: "เข้าสู่ระบบไม่ได้โปรดติดต่อ Mochi เพื่อขอความช่วยเหลือนะคะ!",
    button_popup_login_error: "อินบ็อกซ์ Mochi",
    message_popup_register_error: "การสร้างบัญชีไม่ไดโปรดติดต่อ Mochi เพื่อขอความช่วยเหลือนะคะ",
    button_popup_register_error: "อินบ็อกซ์ Mochi",
    text_register_exist_account: "คุณมีบัญชีอยู่แล้วคะ?",
    text_redirect_login_page: "ล็อกอิน",
    message_popup_register_success: "คุณได้สร้างบัญชีสำเร็จแล้วค่ะ",
    text_register_with_email: "สร้างบัญชีด้วยอีเมล",
    text_show_password: "คำแนะนำในการลงทะเบียน",
    text_hide_password: "ซ่อน",
    button_login_right_now: "ล็อกอิน",
    would_you_like_login_with_this_account: "คุณต้องการเข้าสู่ระบบด้วยบัญชีนี้หรือไม่?",
    login_width_another_account: "ลงชื่อเข้าใช้ด้วยบัญชีอื่น"
}