/** @format */

import { useState, useEffect } from "react";
import { getKeyWords } from "../../utils/language";
import MochiIcon from "../../assets/images/mochi-icon-register.png";
import "./registerwithemail.scss";
import Email from "../../components/layouts/Inputs/Email";
import Password from "../../components/layouts/Inputs/Password";
import Username from "./../../components/layouts/Inputs/Username/index";
import ButtonActive from "../../components/layouts/Buttons/ButtonActive";
import ButtonDisabled from "../../components/layouts/Buttons/ButtonDisable";
import { ApiRegister } from "../../api/UserApi";
import { setCookie } from "../../utils/cookies";
import {
    checkReferrer,
    getReferrer,
    getUrlCallback,
    getUrlDeviceType,
    getUrlLanguage,
} from "../../utils/url";
import { useNavigate } from "react-router-dom";
import Popup from "../../components/layouts/Popup/index";
import ReCAPTCHA from "react-google-recaptcha";

const RegisterWithEmail = () => {
    const siteKeyCapcha = process.env.REACT_APP_SITE_KEY;

    const urlCallback = getUrlCallback();
    const language = getUrlLanguage();
    const deviceType = getUrlDeviceType();

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [username, setUsername] = useState("");
    const [usernameError] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [showCapCha, setShowCapCha] = useState(false);
    const [isCapCha, setIsCapCha] = useState(false);
    const [statusRegister, setStatusRegister] = useState(true);
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    const [message, setMessage] = useState("");
    const [type, setType] = useState("");
    const [image, setImage] = useState("");
	const [check, setCheck] = useState(false);
    const handleClosePopup = () => {
        setShowPopup(false);
    };
    const handleShowPopupRegisterError = () => {
        setMessage(getKeyWords("message_popup_register_error"));
        setType("error");
        setImage("mochi_error.png");
        setShowPopup(true);
    };
    /**
     * set error message of email if register failed
     * @param err
     */
    const handleSetEmailError = (err) => {
        if (err !== undefined && err.length > 0) {
            setEmailError(err);
        }
    };

    /**
     * logic when user click button register
     * @returns {Promise<void>}
     */
    const handleRegister = async () => {
        setShowCapCha(true);
        const data = {
            display_name: username,
            email,
            password,
            lang: "vn",
            trial_course: 1,
        };
        if (isCapCha) {
            const response = await ApiRegister(data);
            handleAfterRegister(response);
        }
    };
    /**
     * handle logic after successful register
     * @param response
     */
    const handleAfterRegister = (response) => {
        const status = response.status;
        if (status === 200) {
            const data = response.data;
            if (data.code === 0) {
                if (data.errors.email) {
                    handleSetEmailError(data.errors.email[0]);
					setCheck(prev => !prev)
                } else {
                    setStatusRegister(false);
                }
            } else {
                const userToken = data.user.user_token;
                setCookie("user_token", userToken, 365);
                if (checkReferrer(getReferrer())) {
                    window.location.href = `${urlCallback}?token=${userToken}`;
                }
            }
        } else {
            setStatusRegister(false);
        }
    };

    //handle Capcha
    const handleChangeCapCha = (value) => {
        if (value) {
            setIsCapCha(true);
        }
    };
    useEffect(() => {
        setIsSubmit(
            username.length > 0 && password.length > 0 && email.length > 0
        );
    }, [username, password, email]);

    useEffect(() => {
        if (!statusRegister) {
            handleShowPopupRegisterError();
        }
    }, [statusRegister]);
    return (
        <>
            <div className="text-center register">
                <div className="register__title">
                    <p>{getKeyWords("title_page_register_2")}</p>
                </div>
                <img
                    src={MochiIcon}
                    alt="icon-signup"
                    className="register__image"
                />
                <div className="register-box__input">
                    <Username
                        placeholder={getKeyWords(
                            "placeholder_input_username_register"
                        )}
                        setUsername={(value) => setUsername(value)}
                        messageError={usernameError}
                    />
                    <Email
                        placeholder={getKeyWords(
                            "placeholder_input_email_register"
                        )}
                        setEmailError={setEmailError}
                        setEmail={(value) => setEmail(value)}
                        messageError={emailError}
						check={check}
                    />
                    <Password
                        placeholder={getKeyWords(
                            "placeholder_input_password_register"
                        )}
                        setPassword={(value) => setPassword(value)}
                        messageError={passwordError}
                    />
                    {showCapCha && (
                        <div className="login__capcha">
                            <ReCAPTCHA
                                // size="invisible"
                                sitekey={siteKeyCapcha}
                                onChange={handleChangeCapCha}
                            />
                        </div>
                    )}
                </div>
                <div className="register__action">
                    {isSubmit ? (
                        <ButtonActive
                            action={handleRegister}
                            title={getKeyWords("button_register")}
                        />
                    ) : (
                        <ButtonDisabled
                            title={getKeyWords("button_register")}
                        />
                    )}
                </div>
                <div className="box--redirect">
                    <span>{getKeyWords("text_register_exist_account")}</span>
                    <a
                        className="register__span--redirect"
                        // onClick={() =>
                        // 	navigate(
                        // 		`/login?url-callback=${urlCallback}&lang=${language}&deviceType=${deviceType}`
                        // 	)
                        // }
                        href={`/login?url-callback=${urlCallback}&lang=${language}&deviceType=${deviceType}`}>
                        {getKeyWords("text_redirect_login_page")}
                    </a>
                </div>
            </div>
            {showPopup && (
                <Popup
                    message={message}
                    image={image}
                    type={type}
                    setShowPopup={handleClosePopup}
                />
            )}
        </>
    );
};

export default RegisterWithEmail;