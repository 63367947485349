/** @format */

import { apps } from "../constants/webs";

/**
 * get referrer
 * @returns {string}
 */
export const getReferrer = () => {
    return document.referrer;
};

/**
 * get param
 * @param key
 * @param url
 * @returns {string}
 */
export const getParam = (key) => {
    let urlParams = undefined;
    if (typeof URLSearchParams !== "undefined") {
        urlParams = new URLSearchParams(window.location.search);
    }

    if (urlParams !== undefined) {
        if (urlParams.has(key)) {
            return urlParams.get(key);
        }
    }
    return "";
};

export const getUrlCallback = () => {
    const checkUrlCallback = getParam("url-callback");
    return checkUrlCallback;
};

export const checkUrlCallback = (url, token) => {
    const checkUrl = url.split("?");
    if (Array.isArray(checkUrl) && checkUrl.length > 1) {
        return `${url}&token=${token}`;
    } else {
        return `${url}?token=${token}`;
    }
};

export const getUrlLanguage = () => {
    return getParam("lang");
};

export const getUrlDeviceType = () => {
    const deviceType = getParam("deviceType");
    return deviceType === "" ? 3 : deviceType;
};

export const getAppVersion = () => {
    return getParam("appVersion");
};

/**
 * check referrer
 * @param referrer
 * @returns {boolean}
 */
export const checkReferrer = (referrer) => {
    // if (referrer !== "") {
    //     const check = apps.find((item) => item === referrer);
    //     if (check === undefined) {
    //         return false;
    //     }
    // }
    return true;
};
