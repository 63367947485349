export const keywords = {
    header_page_login: "로그인",
    header_page_register: "계정 생성하기",
    title_page_login: "Đăng nhập tài khoản học MochiMochi",
    login_with_google: "G+으로 로그인",
    login_with_facebook: "Facebook으로 로그인",
    login_with_apple: "Apple으로 로그인",
    placeholder_input_username_register: "이름",
    placeholder_input_email_register: "이메일 입력하기",
    placeholder_input_password_register: "비밀번호 만들기",
    placeholder_input_email_login: "이메일 입력하기",
    placeholder_input_password_login: "비밀번호 입력하기",
    or: "나",
    button_login: "로그인",
    button_register: "계정 생성하기",
    text_forgot_pw: "로그인이 안 되시나요?",
    text_no_account: "계정이 아직 안 계신가요?",
    text_create_account: "계정 생성하기",
    title_page_register_1 : "계정을 어떻게 만드시겠습니까?",
    title_page_register_2 : "모치 계정 만들기",
    register_with_google : "Tạo tài khoản với G+",
    register_with_facebook : "Tạo tài khoản với Facebook",
    message_popup_lost_password: "비밀번호 변경에 대한 도움을 받으려면 모치에 편지함을 보내주십시오.",
    message_popup_login_success: "You've logged in successfully!!",
    message_popup_login_error: "Đăng nhập không thành công. Bạn hãy liên hệ với Mochi để được hỗ trợ nhé!",
    button_popup_login_error: "Inbox cho Mochi",
    message_popup_register_error: "Tạo tài khoản không thành công. Bạn hãy liên hệ với Mochi để được hỗ trợ nhé!",
    button_popup_register_error: "Inbox cho Mochi",
    text_register_exist_account: "계정이 이미 계신가요?",
    text_redirect_login_page: "로그인",
    message_popup_register_success: "Bạn đã tạo tài khoản thành công!",
    text_register_with_email: "이메일로 계정 만들기",
    text_show_password: "힌트",
    text_hide_password: "숨기기",
    button_login_right_now: "로그인",
    would_you_like_login_with_this_account: "이 계정으로 로그인하시겠습니까?",
    login_width_another_account: "다른 계정으로 로그인"
}