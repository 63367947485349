import "../action.scss"

const ButtonDisabled = ({title}) => {
    return (
        <div className={"button__action button__action--inactive"}>
            <p>{title}</p>
        </div>
    )
}

export default ButtonDisabled;