/** @format */

import ReCAPTCHA from "react-google-recaptcha";
import Google from "../../components/layouts/Buttons/Google";
import Facebook from "../../components/layouts/Buttons/Facebook";
import Apple from "../../components/layouts/Buttons/Apple";
import Email from "../../components/layouts/Inputs/Email";
import Password from "../../components/layouts/Inputs/Password";
import ButtonDisabled from "../../components/layouts/Buttons/ButtonDisable";
import ButtonActive from "../../components/layouts/Buttons/ButtonActive";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import "./index.scss";
import { ApiGetUserInfo, ApiLogin } from "../../api/UserApi";
import {
	checkReferrer,
    checkUrlCallback,
	getParam,
	getReferrer,
	getUrlCallback,
	getUrlDeviceType,
	getUrlLanguage,
} from "../../utils/url";
import { deleteCookie, getCookie, setCookie } from "../../utils/cookies";
import {
    LoginWithApple,
    LoginWithFacebook,
    LoginWithGoogle,
} from "../../utils/firebase-config";
import { getKeyWords } from "../../utils/language";
import Popup from "../../components/layouts/Popup/index";
import { ModalUserLoginCurrentAccount } from "../../components/common/modal";
import { handleSendMessageToExtension } from "../../utils/extension";

const Login = () => {
	const siteKeyCapcha = process.env.REACT_APP_SITE_KEY;
	const urlCallback = getUrlCallback();
	const language = getUrlLanguage();
	const deviceType = getUrlDeviceType();

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [isCapCha, setIsCapCha] = useState(false);
    const [showCapCha, setShowCapCha] = useState(false);
    const [statusLogin, setStatusLogin] = useState(true);
    const [showPopup, setShowPopup] = useState(false);
    const [message, setMessage] = useState("");
    const [type, setType] = useState("");
    const [image, setImage] = useState("");
    const [isModalUserInfoOpen, setIsModalUserInfoOpen] = useState(true);
	const [check, setCheck] = useState(false);

    //ref capcha

    const handleShowPopupForgot = () => {
        setMessage(getKeyWords("message_popup_lost_password"));
        setType("lost-password");
        setImage("mochi_notification.png");
        setShowPopup(true);
    };
    const handleShowPopupLoginError = () => {
        setMessage(getKeyWords("message_popup_login_error"));
        setType("error");
        setImage("mochi_error.png");
        setShowPopup(true);
    };

    /**
     * login with google
     * @returns {Promise<void>}
     */
    const handleLoginWithGoogle = async () => {
        const response = await LoginWithGoogle();
        handleAfterLogin(response);
    };

    /**
     * login with facebook
     * @returns {Promise<void>}
     */
    const handleLoginWithFb = async () => {
        const response = await LoginWithFacebook();
        handleAfterLogin(response);
    };

    /**
     * login with apple
     * @returns {Promise<void>}
     */
    const handleLoginWithApple = async () => {
        const response = await LoginWithApple();
        // handleAfterLogin(response);
    };

    /**
     * logic when user click button login
     * @returns {Promise<void>}
     */
    const handleLogin = async () => {
        // setEmailError("");
        const data = { email, password };
        setShowCapCha(true);
        // Khi mà có capcha mới call api.
        if (isCapCha) {
            const response = await ApiLogin(data);
            handleAfterLogin(response);
        }
    };

    /**
     * set error message of email if login failed
     * @param err
     */
    const handleSetEmailError = (err) => {
        if (err !== undefined && err.length > 0) {
            setEmailError(err);
        }
    };

    /**
     * set error message of password if login failed
     * @param err
     */
    const handleSetPasswordError = (err) => {
        if (err !== undefined && err.length > 0) {
            setPasswordError(err);
        }
    };

    /**
     * handle logic after successful login
     * @param response
     */
    const handleAfterLogin = (response) => {
        const status = response.status;
        if (status === 200) {
            const data = response.data;
            if (data.code === 0) {
                if (data.lang === "messages.email_not_exits") {
                    handleSetEmailError(data.msg);
                } else if (data.lang === "messages.password_fails") {
					setCheck(prev => !prev)
                    handleSetPasswordError(data.msg);
                }
                if (data.errors) {
                    handleSetEmailError(data.errors);
                    handleSetPasswordError(data.errors);
                    setStatusLogin(false);
					setCheck(prev => !prev)
                }
            } else {
                const userToken = data.user.user_token;
                deleteCookie("user_token");
                setCookie("user_token", userToken, 365);

                handleSendMessageToExtension(userToken);
                if (checkReferrer(getReferrer())) {
                    window.location.href = `${urlCallback}?token=${userToken}`;
                }
            }
        } else {
            setStatusLogin(false);
        }
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    useEffect(() => {
        setIsSubmit(email.length > 0 && password.length > 0);
    }, [email, password]);

    useEffect(() => {
        if (!statusLogin) {
            handleShowPopupLoginError();
        }
    }, [statusLogin]);

    const urlCallbackLink = getParam("url-callback");

    // Check if user has been login
    const [userInfo, setUserInfo] = useState();
    const userInfoFn = async (userTokenIfExists) => {
        const res = await ApiGetUserInfo(userTokenIfExists);
        setUserInfo(res.data.data);
    };

    useEffect(() => {
        const userTokenIfExists = getCookie("user_token");
        if (userTokenIfExists) {
            userInfoFn(userTokenIfExists);
        }
    }, []);

    // open new tab chat with Mochi when click button
    const handleInboxMochi = () => {
        let lang = getUrlLanguage();

        if (lang === "vi") {
            let url = "https://m.me/mochidemy";
            let win = window.open(url, "_blank");
            win.focus();
        } else {
            let url = "https://m.me/mochiglobal";
            let win = window.open(url, "_blank");
            win.focus();
        }
    };
    // capcha
    function handleChangeCapCha(value) {
        if (value) {
            setIsCapCha(true);
        }
    }
    const handleErrorCapCha = () => {};
    return (
        <>
            <div className={"text-center login"}>
                <div className={"login__boxTitle"}>
                    <p>{getKeyWords("title_page_login")}</p>
                </div>
                <Google
                    title={getKeyWords("login_with_google")}
                    action={handleLoginWithGoogle}
                />
                <Facebook
                    title={getKeyWords("login_with_facebook")}
                    action={handleLoginWithFb}
                />
                <Apple
                    title={getKeyWords("login_with_apple")}
                    action={handleLoginWithApple}
                />
                <p className={"login__switch"}>{getKeyWords("or")}</p>
                <div className={"login__boxInput"}>
                    <Email
                        placeholder={getKeyWords(
                            "placeholder_input_email_login"
                        )}
                        setEmailError={setEmailError}
                        setEmail={(value) => setEmail(value)}
                        messageError={emailError}
                    />
                    <Password
                        placeholder={getKeyWords(
                            "placeholder_input_password_login"
                        )}
                        setPassword={(value) => setPassword(value)}
                        messageError={passwordError}
						check={check}
                    />
                    {showCapCha && (
                        <div className="login__capcha">
                            <ReCAPTCHA
                                // size="invisible"
                                sitekey={siteKeyCapcha}
                                onChange={handleChangeCapCha}
                                onErrored={handleErrorCapCha}
                            />
                        </div>
                    )}
                </div>
                <div className={"login__action"}>
                    {isSubmit ? (
                        <ButtonActive
                            action={handleLogin}
                            title={getKeyWords("button_login")}
                        />
                    ) : (
                        <ButtonDisabled title={getKeyWords("button_login")} />
                    )}
                </div>

                <p
                    className={"login__title__forgotPw"}
                    onClick={() => handleShowPopupForgot()}>
                    {getKeyWords("text_forgot_pw")}
                </p>
                <p style={{ fontWeight: "bold" }}>
                    <span>{getKeyWords("text_no_account")} </span>
                    <a
                        href={`/register?url-callback=${urlCallbackLink}&lang=${language}&deviceType=${deviceType}`}>
                        <span className={"login__title__register"}>
                            {getKeyWords("text_create_account")}
                        </span>
                    </a>
                </p>
            </div>
            {showPopup && (
                <Popup
                    message={message}
                    image={image}
                    type={type}
                    setShowPopup={handleClosePopup}
                    handleInboxMochi={handleInboxMochi}
                />
            )}
            {userInfo && (
                <ModalUserLoginCurrentAccount
                    userInfo={userInfo}
                    setIsModalUserInfoOpen={setIsModalUserInfoOpen}
                    isModalUserInfoOpen={isModalUserInfoOpen}
                    urlCallback={urlCallback}
                    language={language}
                    deviceType={deviceType}
                />
            )}
        </>
    );
};

export default Login;
