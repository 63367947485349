export const keywords = {
    header_page_login: "ログイン",
    header_page_register: "アカウント登録",
    title_page_login: "MochiMochiアカウントにログインする",
    login_with_google: "Googleでログイン",
    login_with_facebook: "Facebookでログイン",
    login_with_apple: "Appleでログイン",
    placeholder_input_username_register: "名前",
    placeholder_input_email_register: "Emailアドレスを正確に入力してください",
    placeholder_input_password_register: "Passwordを作成する",
    placeholder_input_email_login: "Emailを入力してください",
    placeholder_input_password_login: "Passwordを入力してください",
    or: "または",
    button_login: "ログイン",
    button_register: "アカウント登録",
    text_forgot_pw: "Passwordを忘れた",
    text_no_account: "アカウントがありませんか？",
    text_create_account: "アカウントを作成する",
    title_page_register_1 : "どうやって学習アカウントを作成したいですか",
    title_page_register_2 : "アカウントを作成する",
    register_with_google : "G+でアカウント登録",
    register_with_facebook : "Facebookでアカウント登録",
    message_popup_lost_password: "Passwordを変えるなら、Mochiに連絡ください",
    message_popup_login_success: "MochiMochiアカウントへのログインに成功しました!!",
    message_popup_login_error: "ログインに失敗しました。サポートが必要な場合は Mochi に連絡してください。",
    button_popup_login_error: "Mochiに連絡する",
    message_popup_register_error: "アカウント登録に失敗しました。サポートが必要な場合は Mochi に連絡してください。",
    button_popup_register_error: "Mochiに連絡する",
    text_register_exist_account: "アカウントはありますか？",
    text_redirect_login_page: "ログイン",
    message_popup_register_success: "Bạn đã tạo tài khoản thành công!",
    text_register_with_email: "Emailでアカウントを作成する",
    text_show_password: "表示",
    text_hide_password: "隠す",
    button_login_right_now: "ログイン" ,
    would_you_like_login_with_this_account: "このアカウントでログインしますか?",
    login_width_another_account: "別のアカウントでログインする"
}