import "./index.scss";
import "../social.scss";

const Facebook = ({title, action}) => {
    return (
        <div className={"facebook button__social"}>
            <div className={"facebook__box button__social__box"} onClick={action}>
                <img
                    src={require("../../../../assets/images/facebook.png")}
                    alt="icon google"
                />
                <p>{title}</p>
            </div>
        </div>
    )
}

export default Facebook;