/** @format */

import ImageBackground from "../../assets/images/img.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container, Row } from "react-bootstrap";
import Header from "./Header";
import "./index.scss";
import "../../assets/css/index.scss";
import { useNavigate } from "react-router-dom";
import { checkReferrer, getReferrer, getUrlCallback, getUrlDeviceType, getUrlLanguage } from "../../utils/url";
import { getKeyWords } from "../../utils/language";

const Layout = ({ children }) => {
    const urlCallback = getUrlCallback();
	const language = getUrlLanguage();
	const deviceType= getUrlDeviceType();

    const referrer = getReferrer();
    const check = checkReferrer(referrer);
    if (!check) {
        //todo
        //show popup error
    }
    const Navigate = useNavigate();
    const pathName = window.location.pathname;
    const iconClose = require("../../assets/images/close.png");
    const iconBack = require("../../assets/images/back.png");
    let titleHeader;
    let iconHeader;
    let actionIcon;
    switch (pathName) {
        case "/register":
            titleHeader = getKeyWords("header_page_register");
            iconHeader = iconBack;
            actionIcon = () => {
                Navigate(`/login?url-callback=${urlCallback}&lang=${language}&deviceType=${deviceType}`);
            };
            break;
        case "/register-with-email":
            titleHeader = getKeyWords("header_page_register");
            iconHeader = iconBack;
            actionIcon = () => {
                Navigate(`/login?url-callback=${urlCallback}&lang=${language}&deviceType=${deviceType}`);
            };
            break;
        default:
            titleHeader = getKeyWords("header_page_login");
            iconHeader = iconClose;
            if (referrer !== "") {
                actionIcon = () => {
                    window.location.assign(referrer);
                };
            } else {
                actionIcon = () => {
                    return true;
                };
            }
            break;
    }

    const styleBackground = {
        background: `url(${ImageBackground})`,
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
    };

    return (
        <div className={"layout"}>
            <div className={"layout__element"} style={styleBackground}>
                <Container fluid={true} style={{ padding: 0 }}>
                    <Row className="justify-content-md-center">
                        <Col lg={6}>
                            <div className={"layout__content"}>
                                <Header
                                    title={titleHeader}
                                    icon={iconHeader}
                                    actionIcon={actionIcon}
                                />
                                {children}
                                {/*<img src={require("../../assets/images/mochi.png")} alt={"mochi"}*/}
                                {/*     style={{width: "150px", position: "absolute", bottom: 0, left: 0}}/>*/}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default Layout;
