/**
 * set cookie
 * @param cookieName
 * @param cookieValue
 * @param day
 */
export const setCookie = (cookieName, cookieValue, day) => {
	const date = new Date();
	date.setDate(date.getDate() + day);
	let formatDatetoUTC = date.toUTCString().replace("GMT", "UTC");

	document.cookie = `${cookieName}=${cookieValue}; expires=${formatDatetoUTC} ; path=/`;
};

/**
 * get cookie
 * @param cookieName
 * @returns {string|null}
 */
export const getCookie = (cookieName) => {
	let cookie = document.cookie;
	let arrCookie = cookie.split("; ");
	let cookieStr = arrCookie.find((item) => item.includes(cookieName));
	if (cookieStr === undefined) {
		return null;
	}
	return cookieStr.split("=")[1];
};

/**
 * delete cookie
 * @param cookieName
 */
export const deleteCookie = (cookieName) => {
	document.cookie =
		cookieName + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};
