import React, {useState, useEffect} from 'react';
import "../input.scss";
import {validateUserName} from "../../../../utils/validate";
const Username = ({placeholder, setUsername, messageError}) => {
    const [message, setMessage] = useState("");
    const [classError, setClassError] = useState("");
    const handleSetUsername = (value) => {
        const validate = validateUserName(value);
        if (validate.status) {
            setClassError("");
            setMessage("");
            setUsername(value.trim());
        }
        else {
            setMessage(validate.message);
            setClassError("input__error");
            setUsername("");
        }
    };
    useEffect(() => {
        if (messageError.length > 0) {
            setMessage(messageError);
            setClassError("input__error")
        }
    }, [messageError])
    return (
        <div className="input__group">
            <input
                type="text"
                onChange={(e) => handleSetUsername(e.target.value)}
                placeholder={placeholder}
                className={`form-control ${classError}`}
            />
             <p className={"input__message__error"}>
                <small>{message}</small>
            </p>
        </div>
    )
}

export default Username;