export const keywords = {
    header_page_login: "Login",
    header_page_register: "Create an account",
    title_page_login: "Log into your MochiMochi account",
    login_with_google: "Log in with G+",
    login_with_facebook: "Log in with Facebook",
    login_with_apple: "Log in with Apple",
    placeholder_input_username_register: "Your name",
    placeholder_input_email_register: "Enter your email precisely",
    placeholder_input_password_register: "Create password",
    placeholder_input_email_login: "Enter your email",
    placeholder_input_password_login: "Enter your password precisely",
    or: "OR",
    button_login: "Login",
    button_register: "Create an account",
    text_forgot_pw: "Forgot password?",
    text_no_account: "Don't have an account yet?",
    text_create_account: "Create a new account",
    title_page_register_1 : "How do you want to create an account?",
    title_page_register_2 : "Create a Mochi account",
    register_with_google : "Sign up with G+",
    register_with_facebook : "Sign up with Facebook",
    message_popup_lost_password: "Please inbox Mochi if you want to change your password",
    message_popup_login_success: "You've logged in successfully!!",
    message_popup_login_error: "Login unsuccessful.Please contact Mochi for support!",
    button_popup_login_error: "Inbox Mochi",
    message_popup_register_error: "Signup unsuccessful.Please contact Mochi for support!",
    button_popup_register_error: "Inbox Mochi",
    text_register_exist_account: "Already have an account?",
    text_redirect_login_page: "Login",
    message_popup_register_success: "You've successfully created an account!",
    text_register_with_email: "Create an account with your email",
    text_show_password: "Show",
    text_hide_password: "Hide" 

}