/** @format */
/*global chrome*/
import { Modal, ModalBody } from "reactstrap";

import ButtonActive from "../../layouts/Buttons/ButtonActive";
import closeButton from "../../../assets/images/close.png";
import AvatarUser from "../../../assets/images/Mochi-avatar-no-border.png";
import "./modal.scss";
import "./userInfo.scss";
import { getCookie } from "../../../utils/cookies";
import { getKeyWords } from "../../../utils/language";
import { useNavigate } from "react-router-dom";
import { checkUrlCallback } from "../../../utils/url";
import { handleSendMessageToExtension } from "../../../utils/extension";

const ModalUserLoginCurrentAccount = ({
	userInfo,
	setIsModalUserInfoOpen,
	isModalUserInfoOpen,
	urlCallback,
	language,
	deviceType,
}) => {
	const navigate = useNavigate();

	const userToken = getCookie("user_token");

	const handleClosePopup = () => {
		window.location.href = urlCallback;
	};

	const handleSignInWithOtherAccount = () => {
		setIsModalUserInfoOpen(false);
		if (urlCallback === "") {
			navigate(
				`/?url-callback=${urlCallback}&lang=${language}&deviceType=${deviceType}`
			);
		}
	};

	const handleSignInRightNow = () => {
		if (urlCallback === "" || urlCallback === undefined) {
			handleSendMessageToExtension(userToken);
			window.open = urlCallback + "?token=" + userToken;
			window.close();
		} else {
			// window.location.href = urlCallback + "?token=" + userToken;
			window.location.href = checkUrlCallback(urlCallback, userToken);
		}
	};

	return (
		<div>
			<Modal
				wrapClassName="mochi-modal-wrapper"
				className="modal-mochi"
				isOpen={isModalUserInfoOpen}
				backdrop={"static"}
			>
				<ModalBody>
					<div
						className="mochi-modal-image-close-outer"
						onClick={() => handleClosePopup()}
					>
						<img
							className="mochi-modal-image-close"
							src={closeButton}
							alt="close button"
						/>
					</div>

					<div className="mochi-modal-body">
						<p className="mochi-modal-body-title">
							{getKeyWords(
								"would_you_like_login_with_this_account"
							)}
						</p>
						{userInfo && (
							<div className="user__information">
								<div className="user__avt">
									<div
										className={`img_outer ${
											userInfo.expired_day === null
												? "img_outer_free"
												: "img_outer_premium"
										}`}
									>
										<img src={AvatarUser} alt="" />
									</div>
									{userInfo.expired_day === null ? (
										<div
											className={`user_level user_level_free`}
										>
											Free Account
										</div>
									) : (
										<div
											className={`user_level user_level_premium`}
										>
											Premium Account
										</div>
									)}
								</div>
							</div>
						)}
						<p className="mochi-modal-body-name">
							{userInfo.display_name}
						</p>
						<p className="mochi-modal-body-email">
							Email: {userInfo.email}
						</p>
						<ButtonActive
							title={getKeyWords("button_login_right_now")}
							action={() => handleSignInRightNow()}
						/>
						<p
							className="mochi-modal-body-other-account"
							onClick={() => handleSignInWithOtherAccount()}
						>
							{getKeyWords("login_width_another_account")}
						</p>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
};

export default ModalUserLoginCurrentAccount;
