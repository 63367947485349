import "./index.scss";
import "../social.scss";

const Google = ({title, action}) => {
    return (
        <div className={"google button__social"}>
            <div className={"google__box button__social__box"} onClick={action}>
                <img src={require("../../../../assets/images/google.png")}
                     alt="icon google"/>
                <p>{title}</p>
            </div>
        </div>
    )
}

export default Google;