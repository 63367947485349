export const keywords = {
    header_page_login: "Đăng nhập",
    header_page_register: "Tạo tài khoản mới",
    title_page_login: "Đăng nhập tài khoản học MochiMochi",
    login_with_google: "Đăng nhập với G+",
    login_with_facebook: "Đăng nhập với Facebook",
    login_with_apple: "Đăng nhập với Apple",
    placeholder_input_username_register: "Tên của bạn",
    placeholder_input_email_register: "Nhập chính xác email của bạn",
    placeholder_input_password_register: "Tạo mật khẩu (dễ nhớ chút nhé ^^)",
    placeholder_input_email_login: "Nhập email tài khoản học",
    placeholder_input_password_login: "Nhập chính xác mật khẩu của bạn",
    or: "HOẶC",
    button_login: "Đăng nhập",
    button_register: "Tạo tài khoản",
    text_forgot_pw: "Quên mật khẩu?",
    text_no_account: "Chưa có tài khoản?",
    text_create_account: "Tạo tài khoản học mới",
    title_page_register_1 : "Bạn muốn tạo tài khoản bằng cách nào nhỉ?",
    title_page_register_2 : "Cùng tạo 1 tài khoản Mochi nào",
    register_with_google : "Tạo tài khoản với G+",
    register_with_facebook : "Tạo tài khoản với Facebook",
    message_popup_lost_password: "Bạn hãy inbox cho Mochi để được hỗ trợ đổi mật khẩu nhé",
    message_popup_login_success: "Bạn đã đăng nhập thành công!!",
    message_popup_login_error: "Đăng nhập không thành công. Bạn hãy liên hệ với Mochi để được hỗ trợ nhé!",
    button_popup_login_error: "Inbox cho Mochi",
    message_popup_register_error: "Tạo tài khoản không thành công. Bạn hãy liên hệ với Mochi để được hỗ trợ nhé!",
    button_popup_register_error: "Inbox cho Mochi",
    text_register_exist_account: "Bạn đã có tài khoản?",
    text_redirect_login_page: "Đăng nhập ngay",
    message_popup_register_success: "Bạn đã tạo tài khoản thành công!",
    text_register_with_email: "Tự tạo tài khoản với email",
    text_show_password: "Hiển thị",
    text_hide_password: "Ẩn",
    button_login_right_now: "Đăng nhập ngay",
    would_you_like_login_with_this_account: "Bạn có muốn đăng nhập bằng tài khoản này?",
    login_width_another_account: "Đăng nhập bằng tài khoản khác"
}