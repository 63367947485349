import React from "react";
import { useNavigate } from "react-router-dom";
import "./button-redirect.scss";
const ButtonRedirect = ({
	title,
	urlCallback,
	deviceType = "",
	language = "",
}) => {
	const navigate = useNavigate();
	return (
		// <div className={"normal button__social"} onClick={() => navigate(`/register-with-email?url-callback=${urlCallback}&lang=${language}&deviceType=${deviceType}`)}>
		//     <div className={"normal__box button__social__box"}>
		//         <p style={{color: "black"}}>{title}</p>
		//     </div>
		// </div>
		<a
            className={"normal button__social"} 
			href={`/register-with-email?url-callback=${urlCallback}&lang=${language}&deviceType=${deviceType}`}
            style={{
                textDecoration : 'none'
            }}
		>
			<div className={"normal button__social"}>
				<div className={"normal__box button__social__box"}>
					<p style={{ color: "black" }}>{title}</p>
				</div>
			</div>
		</a>
	);
};

export default ButtonRedirect;
