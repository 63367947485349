import "./index.scss";

const Header = ({icon, title, actionIcon}) => {
    return (
        <div className={"header"}>
            <img
                onClick={actionIcon}
                src={icon}
                alt={"close"}
            />
            <p>{title}</p>
        </div>
    )
}

export default Header;