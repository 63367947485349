/** @format */

import { useEffect, useState } from "react";
import "../input.scss";
import { validateEmail } from "../../../../utils/validate";

const Email = ({
    placeholder,
    setEmail,
    messageError,
    setEmailError,
    email,
}) => {
    const [message, setMessage] = useState("");
    const [classError, setClassError] = useState("");
    const handleSetEmail = (value) => {
        const validate = validateEmail(value);
        if (validate.status) {
            setClassError("");
            setMessage("");
            setEmail(value.trim());
        } else {
            setMessage(validate.message);
            setClassError("input__error");
            setEmail("");
        }
    };

    useEffect(() => {
        if (
            (typeof messageError === "object" && messageError.length > 0) ||
            messageError !== ""
        ) {
            setMessage(messageError);
            setEmailError("");
            setClassError("input__error");
        }
    }, [messageError]);

    return (
        <div className={"input__group"}>
            <input
                type="text"
                onChange={(e) => handleSetEmail(e.target.value)}
                placeholder={placeholder}
                className={`form-control ${classError}`}
                // value={email}
            />
            <p className={"input__message__error"}>
                <small>{message}</small>
            </p>
        </div>
    );
};

export default Email;
