/** @format */

import { useState, useEffect } from "react";
import Google from "../../components/layouts/Buttons/Google/index";
import Facebook from "../../components/layouts/Buttons/Facebook/index";
import {
	LoginWithFacebook,
	LoginWithGoogle,
} from "../../utils/firebase-config";
import { setCookie } from "../../utils/cookies";
import {
	checkReferrer,
	getReferrer,
	getUrlCallback,
	getUrlDeviceType,
	getUrlLanguage,
} from "../../utils/url";
import { useNavigate } from "react-router-dom";
import ButtonRedirect from "../../components/layouts/Buttons/ButtonRedirect/index";
import MochiIcon from "../../assets/images/mochi-icon-register.png";
import { getKeyWords } from "../../utils/language";
import Popup from "../../components/layouts/Popup/index";
import "./register.scss";
import ReCAPTCHA from "react-google-recaptcha";
const Register = () => {
	const siteKeyCapcha = process.env.REACT_APP_SITE_KEY;
	const urlCallback = getUrlCallback();
	const language = getUrlLanguage();
	const deviceType = getUrlDeviceType();

	const [statusRegister, setStatusRegister] = useState(true);
	const navigate = useNavigate();
	const [showPopup, setShowPopup] = useState(false);
	const [showCapCha, setShowCapCha] = useState(false);
	const [isCapCha, setIsCapCha] = useState(false);
	const [message, setMessage] = useState("");
	const [type, setType] = useState("");
	const [image, setImage] = useState("");

	const handleClosePopup = () => {
		setShowPopup(false);
	};

	const handleShowPopupRegisterError = () => {
		setMessage(getKeyWords("message_popup_register_error"));
		setType("error");
		setImage("mochi_error.png");
		setShowPopup(true);
	};

	const handleAfterLogin = (response) => {
		const status = response.status;
		if (status === 200) {
			const data = response.data;
			if (data.code === 0) {
				setStatusRegister(false);
			} else {
				const userToken = data.user.user_token;
				setCookie("user_token", userToken, 365);
				if (checkReferrer(getReferrer())) {
					window.location.href = `${urlCallback}?token=${userToken}`;
				}
			}
		} else {
			setStatusRegister(false);
		}
	};

	const handleLoginWithGoogle = async () => {
		const response = await LoginWithGoogle();
		handleAfterLogin(response);
	};

	const handleLoginWithFb = async () => {
		const response = await LoginWithFacebook();
		handleAfterLogin(response);
	};

	//handle Capcha
	const handleChangeCapCha = (value) => {
		if (value) {
			setIsCapCha(true);
		}
	};

	useEffect(() => {
		if (!statusRegister) {
			handleShowPopupRegisterError();
		}
	}, [statusRegister]);

	return (
		<>
			<div className="text-center sign-up">
				<div className="sign-up__title">
					<p>{getKeyWords("title_page_register_1")}</p>
				</div>
				<img
					src={MochiIcon}
					alt="icon-signup"
					className="sign-up__image"
				/>
				<Facebook
					title={getKeyWords("register_with_facebook")}
					action={handleLoginWithFb}
				/>
				<Google
					title={getKeyWords("register_with_google")}
					action={handleLoginWithGoogle}
				/>
				<p className="sign-up__switch">{getKeyWords("or")}</p>
				<ButtonRedirect
					title={getKeyWords("text_register_with_email")}
					urlCallback={urlCallback}
					language={language}
					deviceType={deviceType}
				/>
				{showCapCha && (
					<div className="login__capcha">
						<ReCAPTCHA
							// size="invisible"
							sitekey={siteKeyCapcha}
							onChange={handleChangeCapCha}
						/>
					</div>
				)}
				<div className="box--redirect">
					<span>{getKeyWords("text_register_exist_account")}</span>
					<a
						className="sign-up__span--redirect"
						// onClick={() =>
						//     navigate(`/login?url-callback=${urlCallback}&lang=${language}&deviceType=${deviceType}`)
						// }>
						href={`/login?url-callback=${urlCallback}&lang=${language}&deviceType=${deviceType}`}
					>
						{getKeyWords("text_redirect_login_page")}
					</a>
				</div>
			</div>
			{showPopup && (
				<Popup
					message={message}
					image={image}
					type={type}
					setShowPopup={handleClosePopup}
				/>
			)}
		</>
	);
};

export default Register;
