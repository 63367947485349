/* global chrome */
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/layouts";
import Login from "./pages/Login";
import Register from "./pages/Register";
import RegisterWithEmail from "./pages/RegisterWithEmail";
import { getParam } from "./utils/url";
import { useEffect } from "react";

const App = () => {
	const lang = getParam("lang");
	localStorage.setItem("language", lang);

	const renderPage = (component) => {
		return <Layout>{component}</Layout>;
	};

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={renderPage(<Login />)} />
				<Route path="/login" element={renderPage(<Login />)} />
				<Route path="/register" element={renderPage(<Register />)} />
				<Route
					path="/register-with-email"
					element={renderPage(<RegisterWithEmail />)}
				/>
				<Route path="*" element={renderPage(<Login />)} />
			</Routes>
		</BrowserRouter>
	);
};

export default App;
