import "../action.scss"

const ButtonActive = ({action, title}) => {
    return (
        <div className={"button__action button__action--active"}>
            <div className={"button__action__boxActive"} onClick={action}>
                <p>
                    {title}
                </p>
            </div>
        </div>
    )
}

export default ButtonActive;