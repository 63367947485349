import {getMessage} from "./language";

/**
 * validate email
 * @param email
 * @returns {{message: string, email, status: boolean}}
 */
export const validateEmail = (email) => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let status = false;
    let message = ""
    if (email.trim().length === 0) {
        message = getMessage("email_required")
    } else if (!(regex.test(email.trim()))) {
        message = getMessage("email_invalid")
    } else {
        status = true
    }

    return {email, status, message}
}

/**
 * validate password
 * @param password
 * @returns {{password, message: string, status: boolean}}
 */
export const validatePassword = (password) => {
    let status = false;
    let message = ""
    if (password.trim().length === 0) {
        message = getMessage("password_required")
    } else if (password.trim().length < 6) {
        message = getMessage("password_min")
    } else {
        status = true
    }

    return {password, status, message}
}

/**
 * validate userName
 * @param userName
 * @returns {{userName, message: string, status: boolean}}
 */
export const validateUserName = (userName) => {
    let status = false;
    let message = ""
    if (userName.trim().length === 0) {
        message = getMessage("user_name_required")
    } else if (userName.trim().length < 3 || userName.trim().length > 15) {
        message = getMessage("user_name_size")
    } else {
        status = true
    }

    return {userName, status, message}
}