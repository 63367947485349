/** @format */

import React from "react";
import Close from "../../../assets/images/close.png";
import "./Pop-up.scss";
import { getKeyWords } from "../../../utils/language";
const Popup = (props) => {
    const { message, image, type, setShowPopup, handleInboxMochi } = props;
    const linkImage = require(`../../../assets/images/${image}`);
    const handleClosePopup = () => {
        setShowPopup(false);
    };
    return (
        <>
            <div className={"container--shadow"} onClick={handleClosePopup} />
            <div className={`pop-up ${type}`}>
                <img
                    src={Close}
                    alt="close-btn"
                    className="pop-up--close"
                    onClick={handleClosePopup}
                />
                <div className={"pop-up__content"}>
                    <img src={linkImage} alt="icon" className="pop-up__image" />
                    <p className="pop-up__message">{message}</p>
                    {(type === "error" || type === "lost-password") && (
                        <button
                            className="button-ibox"
                            onClick={() => handleInboxMochi()}>
                            {getKeyWords("button_popup_register_error")}
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};

export default Popup;
