import {initializeApp} from 'firebase/app';
import {ApiLoginSocial} from '../api/UserApi';
// import {getMessaging, onMessage} from "firebase/messaging";
import {getAuth, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider, signInWithPopup,} from 'firebase/auth';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
export const app = initializeApp(firebaseConfig);
// export const messaging = getMessaging(app);

const auth = getAuth(app);
const providerGoogle = new GoogleAuthProvider();
const providerFacebook = new FacebookAuthProvider();
const providerApple = new OAuthProvider('apple.com');
providerGoogle.setCustomParameters({prompt: 'select_account'});
providerGoogle.addScope('https://www.googleapis.com/auth/userinfo.email');
providerGoogle.addScope("https://www.googleapis.com/auth/userinfo.profile");
providerApple.addScope("email");
providerApple.addScope("name");
providerFacebook.addScope("email");
providerFacebook.setCustomParameters({display: "popup"});

/**
 * login with social
 * @param auth
 * @param provider
 * @param type
 * @returns {Promise<null|AxiosResponse<*>>}
 * @constructor
 */
const LoginWithSocial = async (auth, provider, type) => {
    try {
        const data = await signInWithPopup(auth, provider);
        console.log("data =>", data);
        return await ApiLoginSocial(type, data.user);
    } catch (err) {
        console.log(err);
        return null;
        // lỗi ngoại lệ
    }
}


export const LoginWithGoogle = () => LoginWithSocial(auth, providerGoogle, 'google');
export const LoginWithFacebook = () => LoginWithSocial(auth, providerFacebook, 'facebook');
export const LoginWithApple = () => LoginWithSocial(auth, providerApple, 'apple');