/* global chrome */
const handleSendMessageToExtension = (userToken) => {
    // The ID of the extension we want to talk to.
    let editorExtensionId = process.env.REACT_APP_EXTENSION_ID;
    const extensionBox = document.querySelector(
        ".Oq465NRHaOKegSCssrnhCql2WEIDuYU0"
    );
    if (extensionBox) {
        editorExtensionId = extensionBox.getAttribute("id");
    }
    console.log("editorExtensionId =>", editorExtensionId);
    // Make a simple request:
    chrome.runtime.sendMessage(
        editorExtensionId,
        { user_token: userToken },
        function (response) {}
    );
};
export {
    handleSendMessageToExtension
}