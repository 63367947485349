/** @format */

import axios from "axios";
import { getUrlDeviceType, getAppVersion } from "../utils/url";

const deviceType = getUrlDeviceType();
const appVersion = getAppVersion();

/**
 * create axios
 * @type {AxiosInstance}
 */
const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        PrivateKey: "M0ch1M0ch1_En_$ecret_k3y",
        DeviceType: deviceType,
        AppVersion: appVersion,
    },
    timeout: 5000,
});
//timeout đặt giới hạn thời gian chờ hản hồi 5s

/**
 * method post
 * @param url
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const post = async (url, data) => {
    return await instance.post(url, data);
};

/**
 * method get
 * @param {}} url
 * @returns
 */
export const get = async (url) => {
    return await instance.get(url);
};
