import {useEffect, useState} from "react";
import "../input.scss";
import {validatePassword} from "../../../../utils/validate";
import {getKeyWords} from "../../../../utils/language";
const Password = ({placeholder, setPassword, messageError, check}) => {
    const [message, setMessage] = useState("");
    const [classError, setClassError] = useState("");
    const [type, setType] = useState("password");
    const [textAction, setTextAction] = useState(getKeyWords("text_show_password"));
    const [showAction, setShowAction] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleSetPassword = (value) => {
        value.trim().length > 0 ? setShowAction(true) : setShowAction(false);
        const validate = validatePassword(value);
        if (validate.status) {
            setClassError("");
            setMessage("");
            setPassword(value);
        } else {
            setClassError("input__error");
            setMessage(validate.message);
            setPassword("");
        }
    }

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    useEffect(() => {
        !showPassword ? setType("password") : setType("text");
        !showPassword ? setTextAction(getKeyWords("text_show_password")) : setTextAction(getKeyWords("text_hide_password"));
    }, [showPassword])

    useEffect(() => {
        if (messageError.length > 0 ) {
            setMessage(messageError);
            setClassError("input__error");
        }
    }, [messageError, check])

    return (
        <div className={"input__group"}>
            <div style={{position: "relative"}}>
                <input
                    type={type}
                    onChange={(e) => handleSetPassword(e.target.value)}
                    placeholder={placeholder}
                    className={`form-control ${classError}`}
                />
                {
                    showAction && (
                        <div onClick={handleShowPassword} className={"show_pw"}>
                            {textAction}
                        </div>
                    )
                }
            </div>
            <p className={"input__message__error"}>
                <small>{message}</small>
            </p>
        </div>
    )
}

export default Password