/** @format */

import { post, get } from "./Base";
import ENDPOINT from "./Endpoint";
import { getCookie, deleteCookie } from "../utils/cookies";

const handleGetEndpoint = (endpoint) => {
    return process.env.REACT_APP_API + endpoint;
};
/**
 * api login with email & password
 * @param data
 * @reutrn {Promise<void>}
 * @constructor
 */
export const ApiLogin = async (data) => {
    try {
        await ApiLogout();
        return await post(handleGetEndpoint(ENDPOINT.URL_LOGIN_BY_EMAIL), data);
    } catch (err) {
        // logic
    }
};
/**
 * note
 * dạng hàm mẫu xử lí logic , tái sử dụng
 * mặc định return promise nhưng vì là xử lí logic nên promise không resolve giá gị nào
 * tham số nhận là data (body data mà người dùng nhập)
 */

/**
 * api register with email|password
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export const ApiRegister = async (data) => {
    try {
        await ApiLogout();
        return await post(handleGetEndpoint(ENDPOINT.URL_REGISTER), data);
    } catch (err) {
        console.log(err);
        //logic
    }
};

/**
 * note
 * dạng hàm mẫu xử lí logic , tái sử dụng
 * tham số nhận là data (body data mà người dùng nhập)
 */

/**
 * api login with socials
 * @param provider
 * @param user
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */

export const ApiLoginSocial = async (provider, user) => {
    await ApiLogout();
    return await post(handleGetEndpoint(ENDPOINT.URL_LOGIN_BY_FB_GOOGLE), {
        email: user.email,
        name: user.displayName,
        provider: provider,
        provider_id: user.providerData[0].uid,
        lang: "vn",
        trial_course: 1,
    });
};

/**
 * api logout
 * @returns {Promise<boolean>}
 * @constructor
 */
export const ApiLogout = async () => {
    try {
        const userToken = getCookie("user_token");
        if (userToken !== null) {
            await post(handleGetEndpoint(ENDPOINT.URL_LOGOUT), {
                user_token: userToken,
            });
            deleteCookie("user_token");
        }
        return true;
    } catch (err) {
        deleteCookie("user_token");
        return true;
    }
};

/**
 * api get user
 * @param token
 *
 */
export const ApiGetUser = async (token) => {
    let status = 200;
    try {
        let url =
            handleGetEndpoint(ENDPOINT.URL_GET_USER) + "?user_token=" + token;
        await get(url);
    } catch (err) {
        if (err.response.status !== 200) {
            deleteCookie("user_token");
        }

        status = err.response.status;
    }

    return status;
};

export const ApiGetUserInfo = async (token, server = null) => {
    try {
        let url =
            handleGetEndpoint(ENDPOINT.URL_GET_USER) + "?user_token=" + token;
        return await get(url);
    } catch (err) {
        if (err.response.status !== 200 && server === null) {
            deleteCookie("user_token");
        } else {
            return false;
        }
    }
};
