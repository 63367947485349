import {keywords as ViKeywords} from "../constants/vi";
import {keywords as EnKeywords} from "../constants/en";
import {keywords as KoreaKeywords} from "../constants/korea";
import {keywords as JapanKeywords} from "../constants/japan";
import {keywords as ThailandKeywords} from "../constants/thailand";
import {messageValidator as ViMessageValidator} from "../constants/vi/message";
import {messageValidator as EnMessageValidator} from "../constants/en/message";
import {messageValidator as KoreaMessageValidator} from "../constants/korea/message";
import {messageValidator as JapanMessageValidator} from "../constants/japan/message";
import {messageValidator as ThailandMessageValidator} from "../constants/thailand/message";
import {getParam} from "../utils/url"

let lang = getParam("lang");

if (!lang) {
    lang = localStorage.getItem("language");
}
/**
 * get keyword
 * @param key
 * @returns {*}
 */
export const getKeyWords = (key) => {
    
    switch (lang) {
        case "en":
            return EnKeywords[key]
        case "kr":
            return KoreaKeywords[key]
        case "jp":
            return JapanKeywords[key]
        case "th":
            return ThailandKeywords[key]
        default:
            return ViKeywords[key]
    }
}

/**
 * get message
 * @param key
 * @returns {*}
 */
export const getMessage = (key) => {
    switch (lang) {
        case "en":
            return EnMessageValidator[key]
        case "kr":
            return KoreaMessageValidator[key]
        case "jp":
            return JapanMessageValidator[key]
        case "th":
            return ThailandMessageValidator[key]
        default:
            return ViMessageValidator[key]
    }
}